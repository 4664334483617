import React from 'react';
import { Container } from 'react-bootstrap';

import bio from '../../images/bio.jpg';

interface IndexAboutProps {
  i18n: (path: string) => string;
  refProp: React.RefObject<HTMLDivElement>;
  currentLang: 'pl' | 'en';
}

const IndexAbout: React.FC<IndexAboutProps> = ({ refProp, i18n, currentLang }: IndexAboutProps) => {
  const plText = `Absolwent Wydziału Prawa i Administracji Uniwersytetu Śląskiego w Katowicach, wpisany na listę adwokatów
  przy Okręgowej Radzie Adwokackiej w Katowicach. Doświadczenie zawodowe zdobywał podczas współpracy z
  renomowaną Kancelarią na Śląsku, świadcząc przez kilka lat, stałą obsługę prawną podmiotów gospodarczych
  oraz prowadząc sprawy z zakresu prawa gospodarczego i cywilnego, prawa karnego oraz prawa pracy. Doradza i
  koordynuje działania korporacyjno-prawne Klientów polskich i zagranicznych podczas procesów
  inwestycyjnych. Reprezentuje Klientów podczas negocjacji i transakcji związanych ze zmianą struktury
  właścicielskiej przejmowanych przedsiębiorstw. Od 2019r. dysponuje uprawnieniami mediatora sądowego.
  Biegle posługuje się językiem angielskim.`;

  const enText = `Graduate of the Faculty of Law and Administration of the University of Silesia in Katowice, entered in the register of advocates at the District Bar Association in Katowice. He gained professional experience while working with a reputable Law firm in Silesia, providing for several years permanent legal services to business entities and conducting cases in the field of economic law and civil law, criminal law and labor law. He advises and coordinates corporate and legal activities of Polish and foreign clients during investment processes. He represents clients during negotiations and transactions related to changing the ownership structure of acquired companies. He has been a court mediator since 2019. He is fluent in English. 
  `;
  return (
    <div className="index-about" ref={refProp}>
      <Container>
        <h2>{i18n('about.title')}</h2>
        <div className="index-about__desc" dangerouslySetInnerHTML={{ __html: i18n('about.description') }} />
        <div className="index-about__bio">
          {/* <div>
            <img src={bio} />
          </div> */}
          <div>
            <h3>Adwokat Karol Oczkowicz</h3>
            <p>{currentLang === 'pl' ? plText : enText}</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default IndexAbout;
