import React from 'react';
import { Container } from 'react-bootstrap';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <span>&copy; 2023 Karol Oczkowicz</span>
    </div>
  );
};

export default Footer;
