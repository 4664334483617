import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

const DESKTOP_WIDTH_MEDIA_BREAKPOINT = 1200;
const TABLET_WIDTH_MEDIA_BREAKPOINT = 576;

export enum ScreenSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const useMediaQueries = (): ScreenSize | null => {
  const [currentScreenType, setCurrenScreenType] = useState<ScreenSize | null>(null);

  const watchResize = throttle(() => {
    setCurrenScreenType(getCurrentScreenType());
  }, 300);

  useEffect(() => {
    watchResize();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', watchResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', watchResize);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentScreenType;
};

const isViewportWiderThan = (thresholdEm: number) => {
  if (typeof window !== 'undefined' && window?.matchMedia !== undefined) {
    return window?.matchMedia(`(min-width: ${thresholdEm}px)`).matches || false;
  }
  return false;
};

const isDesktop = (): boolean => {
  return isViewportWiderThan(DESKTOP_WIDTH_MEDIA_BREAKPOINT);
};

const isTablet = (): boolean => {
  return isViewportWiderThan(TABLET_WIDTH_MEDIA_BREAKPOINT);
};

const getCurrentScreenType = (): ScreenSize => {
  if (isDesktop()) {
    return ScreenSize.DESKTOP;
  }

  if (isTablet()) {
    return ScreenSize.TABLET;
  }

  return ScreenSize.MOBILE;
};

export const useScrollDetection: () => boolean = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const watchScroll = throttle(() => {
      setIsScrolled(window.scrollY > 0);
    }, 100);

    window.addEventListener('scroll', watchScroll);

    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  return isScrolled;
};
