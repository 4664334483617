import React from 'react';
import { Container } from 'react-bootstrap';

interface IndexCareerProps {
  i18n: (path: string) => string;
  refProp: React.RefObject<HTMLDivElement>;
}

const IndexCareer: React.FC<IndexCareerProps> = ({ i18n, refProp }: IndexCareerProps) => {
  return (
    <div className="index-career" ref={refProp}>
      <div className="index-services__content-wrapper">
        <Container>
          <div className="index-services__content">
            <h2>{i18n('career.title')}</h2>
            <div dangerouslySetInnerHTML={{ __html: i18n('career.description') }} />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default IndexCareer;
