import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';

const Map: React.FC = () => {
  if (typeof window == 'undefined') return null;

  const coordinates: LatLngExpression = [50.27691669687268, 19.026956693605065];

  return (
    <div className="map-container">
      {true && (
        <MapContainer center={coordinates} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={coordinates}></Marker>
        </MapContainer>
      )}
    </div>
  );
};

export default Map;
