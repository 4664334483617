import React from 'react';
import { Container, Offcanvas } from 'react-bootstrap';

import { useMediaQueries, ScreenSize } from '../../utils/screenUtils';

import logo from '../../images/logo-big.png';
import cross from '../../images/icons/nav-cross.svg';
import hamburger from '../../images/icons/nav-hamburger.svg';

interface NavbarProps {
  scrollToSection: (section: string) => void;
  toggleLang: () => void;
  currentLang: 'pl' | 'en';
}

const Navbar: React.FC<NavbarProps> = ({ scrollToSection, toggleLang, currentLang }: NavbarProps) => {
  const screen = useMediaQueries();
  const isDesktop = screen === ScreenSize.DESKTOP;

  const [showMobileNav, setShowMobileNav] = React.useState(false);

  const handleClose = () => setShowMobileNav(false);
  const handleShow = () => setShowMobileNav(true);

  const handleCloseAndScrollToSection = (section: string) => {
    handleClose();
    setTimeout(() => scrollToSection(section), 500);
  };

  const plNav = {
    about: 'O kancelarii',
    services: 'Zakres usług',
    career: 'Praca i kariera',
    contact: 'Kontakt',
    langSwitch: 'EN',
  };

  const enNav = {
    about: 'About',
    services: 'Services',
    career: 'Career',
    contact: 'Contact',
    langSwitch: 'PL',
  };

  const getCurrentNav = () => {
    return currentLang === 'pl' ? plNav : enNav;
  };

  return (
    <div className="navbar">
      <Container>
        <div className="navbar__content">
          <div>
            <img src={logo} className="logo" onClick={() => scrollToSection('hero')} />
          </div>
          {isDesktop && (
            <div>
              <a onClick={() => scrollToSection('about')}>{getCurrentNav().about}</a>
              <a onClick={() => scrollToSection('services')}>{getCurrentNav().services}</a>
              <a onClick={() => scrollToSection('career')}>{getCurrentNav().career}</a>
              <a onClick={() => scrollToSection('contact')}>{getCurrentNav().contact}</a>
              <a onClick={toggleLang}>{getCurrentNav().langSwitch}</a>
            </div>
          )}
          {!isDesktop && (
            <div>
              <img src={hamburger} onClick={handleShow} className="navbar__hamburger" />
            </div>
          )}
        </div>
      </Container>
      <Offcanvas show={showMobileNav} onHide={handleClose} placement="end">
        <Offcanvas.Body>
          <div className="navigation-offcanvas">
            <img src={cross} onClick={handleClose} className="navigation-offcanvas__close-icon" />
            <div className="navigation-offcanvas__container">
              <a onClick={() => handleCloseAndScrollToSection('about')}>{getCurrentNav().about}</a>
              <a onClick={() => handleCloseAndScrollToSection('services')}>{getCurrentNav().services}</a>
              <a onClick={() => handleCloseAndScrollToSection('career')}>{getCurrentNav().career}</a>
              <a onClick={() => handleCloseAndScrollToSection('contact')}>{getCurrentNav().contact}</a>
              <a onClick={toggleLang}>{getCurrentNav().langSwitch}</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Navbar;
