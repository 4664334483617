import React from 'react';
import { Container } from 'react-bootstrap';

import logo from '../../images/logo-big.png';
import locationIcon from '../../images/icons/location.svg';
import phoneIcon from '../../images/icons/phone.svg';
import mailIcon from '../../images/icons/mail.svg';

import Map from './Map';

interface ContactProps {
  i18n: (path: string) => string;
  refProp: React.RefObject<HTMLDivElement>;
}

const Contact: React.FC<ContactProps> = ({ i18n, refProp }: ContactProps) => {
  return (
    <div className="contact" ref={refProp}>
      <div>
        <Map />
      </div>
      <div>
        <div>
          <img className="contact__logo" src={logo} />

          <div className="contact__line">
            <div>
              <img src={locationIcon} />
            </div>
            <div>
              <span>
                {i18n('contact.addressLine1')}
                <br />
                {i18n('contact.addressLine2')}
              </span>
            </div>
          </div>

          <div className="contact__line">
            <div>
              <img src={phoneIcon} />
            </div>
            <div>
              <span>{i18n('contact.phone')}</span>
            </div>
          </div>

          <div className="contact__line">
            <div>
              <img src={mailIcon} />
            </div>
            <div>
              <span>{i18n('contact.email')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
