import React from 'react';

import heroImage from '../../images/hero-full.jpg';

interface IndexHeroProps {
  refProp: React.RefObject<HTMLDivElement>;
}

const IndexHero: React.FC<IndexHeroProps> = ({ refProp }: IndexHeroProps) => {
  const inlineStyle = {
    backgroundImage: `url(${heroImage})`,
  };
  return (
    <div className="index-hero" style={inlineStyle} ref={refProp}>
      <div className="index-hero__backdrop"></div>
      <div className="index-hero__content">
        <h1>
          <span>Kancelaria adwokacka</span>
          <span>Adwokat Karol Oczkowicz</span>
        </h1>
      </div>
    </div>
  );
};

export default IndexHero;
