import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import MainLayout from '../layouts/MainLayout';

import Navbar from '../components/common/Navbar';
import Contact from '../components/common/Contact';
import Footer from '../components/common/Footer';
import IndexHero from '../components/index/Hero';
import IndexAbout from '../components/index/About';
import IndexServices from '../components/index/Services';
import IndexServicesGrid from '../components/index/ServicesGrid';
import IndexCareer from '../components/index/Career';

// import ogImage from '../images/meta/atakama-og-image.jpg';

import { indexPageData } from '../cms';
export interface IndexContext {
  pl: indexPageData;
  en: indexPageData;
}

const IndexPage: React.FC<PageProps> = pageData => {
  const ctx = pageData.pageContext as IndexContext;

  const servicesPl = ctx.pl.services.services;
  const servicesEn = ctx.en.services.services;

  const [currentLang, setCurrentLang] = React.useState<'pl' | 'en'>('pl');

  const languageAwareServices = currentLang === 'pl' ? servicesPl : servicesEn;
  const i18n = (path: string): string => get(currentLang === 'pl' ? ctx.pl : ctx.en, path, '');

  const toggleLang = () => {
    if (currentLang === 'pl') setCurrentLang('en');
    else setCurrentLang('pl');
  };

  const heroRef = React.useRef<HTMLDivElement>(null);
  const aboutRef = React.useRef<HTMLDivElement>(null);
  const servicesRef = React.useRef<HTMLDivElement>(null);
  const careerRef = React.useRef<HTMLDivElement>(null);
  const contactRef = React.useRef<HTMLDivElement>(null);

  const scrollToSection = (section: string) => {
    switch (section) {
      case 'hero':
        if (heroRef.current) heroRef.current.scrollIntoView();
        break;

      case 'about':
        if (aboutRef.current) aboutRef.current.scrollIntoView();
        break;

      case 'services':
        if (servicesRef.current) servicesRef.current.scrollIntoView();
        break;

      case 'career':
        if (careerRef.current) careerRef.current.scrollIntoView();
        break;

      case 'contact':
        if (contactRef.current) contactRef.current.scrollIntoView();
        break;
    }
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Adwokat Karol Oczkowicz – Kancelaria Adwokacka Katowice</title>
        {/* <meta property="og:image" content={ogImage} /> */}
      </Helmet>
      <div className="index-page">
        <Navbar scrollToSection={scrollToSection} toggleLang={toggleLang} currentLang={currentLang} />
        <IndexHero refProp={heroRef} />
        <IndexAbout i18n={i18n} refProp={aboutRef} currentLang={currentLang} />
        <IndexServices i18n={i18n} refProp={servicesRef} />
        <IndexServicesGrid i18n={i18n} services={languageAwareServices} />
        <IndexCareer i18n={i18n} refProp={careerRef} />
        <Contact i18n={i18n} refProp={contactRef} />
        <Footer />
      </div>
    </MainLayout>
  );
};

export default IndexPage;
