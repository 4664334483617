import React from 'react';
import { Container } from 'react-bootstrap';

import services from '../../images/services-bg.jpg';

interface IndexServicesProps {
  i18n: (path: string) => string;
  refProp: React.RefObject<HTMLDivElement>;
}

const IndexServices: React.FC<IndexServicesProps> = ({ i18n, refProp }: IndexServicesProps) => {
  const inlineStyle = {
    backgroundImage: `url(${services})`,
  };
  return (
    <div className="index-services" style={inlineStyle} ref={refProp}>
      <div className="index-services__backdrop"></div>
      <div className="index-services__content-wrapper">
        <Container>
          <div className="index-services__content">
            <h2>{i18n('services.title')}</h2>
            <div dangerouslySetInnerHTML={{ __html: i18n('services.description') }} />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default IndexServices;
