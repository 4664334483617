import React from 'react';
import { Container } from 'react-bootstrap';

import admin from '../../images/service-icons/admin.svg';
import civil from '../../images/service-icons/civil.svg';
import criminal from '../../images/service-icons/criminal.svg';
import employment from '../../images/service-icons/employment.svg';
import gdpr from '../../images/service-icons/gdpr.svg';
import mediation from '../../images/service-icons/mediation.svg';
import mna from '../../images/service-icons/mna.svg';
import representative from '../../images/service-icons/representative.svg';
import trade from '../../images/service-icons/trade.svg';

import cross from '../../images/icons/cross.svg';

interface IndexServicesGridProps {
  i18n: (path: string) => string;
  services: any[];
}

const IndexServicesGrid: React.FC<IndexServicesGridProps> = ({ i18n, services }: IndexServicesGridProps) => {
  const servicesIcons = [
    {
      type: 'mna',
      icon: mna,
    },
    {
      type: 'civil',
      icon: civil,
    },
    {
      type: 'trade',
      icon: trade,
    },
    {
      type: 'criminal',
      icon: criminal,
    },
    {
      type: 'employment',
      icon: employment,
    },
    {
      type: 'admin',
      icon: admin,
    },
    {
      type: 'gdpr',
      icon: gdpr,
    },
    {
      type: 'mediation',
      icon: mediation,
    },
    {
      type: 'represent',
      icon: representative,
    },
  ];

  const servicesEnrichedWithIcons = React.useMemo(
    () =>
      services.map(s => ({
        ...s,
        icon: servicesIcons.find(i => i.type === s.icon)?.icon,
      })),
    [services]
  );

  const initialState = React.useMemo(() => [...new Array(services.length)].map(i => false), []);

  const [isOpen, setIsOpen] = React.useState<boolean[]>(initialState);
  const isSomeOpen = isOpen.some(elem => elem);

  const openHandler = (index: number) => {
    const isOpenCopy = [...initialState];
    isOpenCopy[index] = true;
    setIsOpen(isOpenCopy);
  };

  const closeAllHandler = () => {
    setIsOpen([...initialState]);
  };

  return (
    <div className="index-services-icons">
      {isSomeOpen && <div className="index-services-icons__overlay" onClick={closeAllHandler} />}
      <Container>
        <div className="index-services-icons__grid">
          {servicesEnrichedWithIcons.map((service, index) => {
            return (
              <div className={`index-services-icons__grid__item`} key={index}>
                <img src={service.icon} onClick={() => openHandler(index)} />
                <span onClick={() => openHandler(index)}>{service.title}</span>

                <div className={`index-services-icons__grid__item__popup ${isOpen[index] ? 'active' : ''}`}>
                  <img
                    className="index-services-icons__grid__item__popup__close"
                    src={cross}
                    onClick={closeAllHandler}
                  />
                  <h5>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default IndexServicesGrid;
